<template>
  <div class="new-booking-communal-facility-page">
    <m-page-title title="Communal Facility" address>
      <img
        class="title-img"
        src="@/static/icon/Location.png"
        alt=""
        slot="describeL"
      />
    </m-page-title>
    <div class="content">
      <div
        class="type-box g-radius-4 g-hover-pointer"
        v-for="(item, index) in itemArr"
        :key="index"
      >
        <span class="title">{{ item.name }}</span>
        <span class="describe">{{ item.description }}</span>
        <span class="link" @click="facilityRules(item.id)"
          >Rules of {{ item.name }}</span
        >
        <el-button type="primary" class="area-btn" @click="fnClick(item)">
          Book
        </el-button>
      </div>

      <el-button type="primary" class="btn" @click="$router.back()">
        Back to new booking
      </el-button>
    </div>
  </div>
</template>

<script>
const itemArr = [];
import { mapMutations, mapState } from "vuex";
export default {
  name: "newBookingCommunalFacility",
  data() {
    return { itemArr };
  },
  computed: {
    ...mapState("booking", ["bookdata"]),
    ...mapState("apartment", ["room", "apartment"]),
  },
  mounted() {
    this.setApartmentList();
  },
  methods: {
    ...mapMutations("booking", ["setFacility"]),
    fnClick(e) {
      this.setFacility(e);
      this.$router.push({ path: "/dateAndTime" });
    },
    //规则界面跳转
    facilityRules(id) {
      this.$router.push({ path: "/bookingRules", query: { id: id } });
    },
    //获取公共设施
    setApartmentList() {
      let params = {
        apartmentUnitId: this.room.apartmentUnitId,
      };
      this.$axios
        .get(this.$api.setApartment(this.apartment.id), { params })
        .then((res) => {
          if (res.code === "1000") {
            this.itemArr.splice(0, this.itemArr.length, ...res.data.list);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
